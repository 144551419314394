#cardChipHigh{ 
    background-color: #F5FAFF ;
    color: #437EF7;
    border-radius: 0.5rem; 
    font-weight: 500; 
    margin: 0 0.5rem 0 0.5rem;
}

#bottomCardInfo{
    min-width: 375px;
    box-shadow: none;
    border: 1px solid #437EF7;
    margin-top: 1rem;
    background-color: #F5FAFF;
    border-radius: 0.4rem;
}
#attchementCardInfo{
    min-width: 175px;
    box-shadow: none;
    border: 1px solid #EAEBF0;
    margin-top: 1rem;
    background-color: #fff;
    border-radius: 0.4rem;
}

.fileName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }