
#Icon {
    transition: transform 0.3s ease;

    &:hover {
        cursor: pointer;
        transform: scale(1.2); 
    }
}
.fileName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }