@import '../../../styles/styles.scss';

#addOutlinedIcon {
   
    // font-size: 2rem; /* Default font size */
    transition: transform 0.3s ease; /* Optional transition on hover */
  
    &:hover {
      cursor: pointer;
      transform: scale(1.2); /* Increase the icon size on hover (optional) */
    }
  }
  
#addOutlinedIconText {
   
    // font-size: 2rem; /* Default font size */
    transition: transform 0.3s ease; /* Optional transition on hover */
  
    &:hover {
      cursor: pointer;
      // transform: scale(0.2);
    }
  }

  #removeIcon {
   
    // font-size: 2rem; /* Default font size */
    transition: transform 0.3s ease; /* Optional transition on hover */
  
    &:hover {
      cursor: pointer;
      transform: scale(1.2); /* Increase the icon size on hover (optional) */
    }
  }