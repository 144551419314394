
.container {
  background: url("../../assets/img/background.png");
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: scroll;
  background-size: cover;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  top: 0px;
  left: 0px;

  .login {
    background: url("../../assets/img/login_background.png");
    background-size: cover;
    height: 600px;
    box-shadow: 0 15px 24px 0 rgba(137, 138, 154, 0.15);
    display: flex;
    padding: 80px;
    border-radius: 15px;

    .loginRandomImage {
      position: relative;
      background: url("../../assets/img/login.png") no-repeat bottom #f4f4f4;
      color: #fff;
      width: 380px;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(https://source.unsplash.com/random?technology);
        background-repeat: no-repeat;
        background-size: cover;
      }

      @media (max-width:1000px) {
        display: none;
      }
    }

    .loginActions {
      width: 525px;
      padding: 0px 0px 0px 48px;
      position: relative;

      @media all and (max-width:540px) {
        width: auto;
      }

      .logoWrapper{
        display: flex;
        align-items: center;
        justify-content: start;
        .logo {
          max-width: 150px;
        }
      }


      p {
        line-height: 24px;
        font-size: 20px;
        margin-top: 20px;
      }

      .loginNotification {
        margin-top: 60px;

        p {
          font-size: 15px;
          display: block;
          padding: 5px 8px;
        }

        .isAuthorizing {
          background: rgba(0, 110, 255, 0.308);
        }

        .error {
          padding: 4px 8px;
          background: rgba(255, 0, 0, 0.3);
        }
      }
      .primaryBtn {
        width: 100%;
        margin-top: 14px;
        font-weight: 600;
        padding-top: 14px;
        padding-bottom: 14px;
        margin-bottom: 35px;
        background: #437EF7;
        color: white;
        margin-bottom: 0.4em !important;
        font-size: 15px;
        text-transform: capitalize !important;
        &.disabled {
         background: gray !important; 
         color: black
        }
      }

      .loginFooter {
        position: absolute;
        bottom: 0px;
      }
    }
  }
}




.alertMargin {
  margin-bottom: 10px;
  background-color: red;
}

