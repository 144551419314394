.rootCard {
    height: calc(100vh - 12.5rem);
    scrollbar-width: 1px !important;
    -ms-overflow-style: inherit;
    overflow-y: scroll !important;
}

.rootCard::-webkit-scrollbar {
    display: inline;
    width: 0.5rem;
}