.sessionStatusChip {
    background-color: #F5FAFF !important;
    border-radius: 0.5rem !important;
    color: #437EF7 !important;
    font-weight: bold !important;
    font-size: 1rem !important;
}

.sessionCoachName {
    font-weight: 500;
    font-size: 1rem;
    color: #437EF7;
    margin-right: 0.5rem !important
}

.sessionDescription {
    margin-top: 1rem;
    color: #5F6D7E;
    font-weight: 500;
    font-size: 1rem;
}