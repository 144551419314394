@import '../../../styles/styles.scss';

#addCircleIcon {
   
    font-size: 2rem; /* Default font size */
    transition: transform 0.3s ease; /* Optional transition on hover */
  
    &:hover {
      cursor: pointer;
      transform: scale(1.2); /* Increase the icon size on hover (optional) */
    }
  }
  
.fileName {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}