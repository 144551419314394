@import "./settings";

body {
  margin: 0;
}

p {
  margin-bottom: 0 !important;
}

h3 {
  margin-bottom: 0 !important;
}


.layout-align-center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.page-root {
  padding: 2rem;
  margin-top: 4rem;
}
.page-root-dashboard {
  padding: 0rem 2rem 2rem 2rem;
  margin-top: 4rem;
}

.section-card {
  height: calc(100vh - 8rem);

  .section-card-header {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .section-card-body {
    padding: 1rem;
    height: calc(100vh - 16rem);
    overflow-y: scroll;
  }
  
  .section-card-body::-webkit-scrollbar {
    display: inline;
    width: 0.5rem;
  }

  .section-card-footer {
    padding: 1rem;
    position: relative;
    bottom: 0;
  }
}

.chip-success {
  color: $chip-text-green !important;
  background-color: $chip-bg-green !important;
}
.chip-warning {
  color: $chip-text-orange !important;
  background-color: $chip-bg-orange !important;
}
.chip-primary {
  color: $chip-text-blue !important;
  background-color: $chip-bg-blue !important;
}
.chip-danger {
  color: $chip-text-red !important;
  background-color: $chip-bg-red !important;
}

.dashboard-chart canvas {
  height: 300px !important;
  width: 300px !important;
}

.authorizedContainer {
  background-color: white;
  height: 100%;
  overflow: hidden;

  .sideNavigation {
    @include transition(ease all 0.4s);
    max-width: 260px;
    z-index: 999;
    background-color: rgb(255, 255, 255);
    border-right: 1px solid rgba(0, 0, 0, 0.12);

    &.collapsed {
      @include transition(ease all 0.4s);
      max-width: 65px;
      white-space: nowrap;
      border-right: 1px solid rgba(0, 0, 0, 0.12);

      @media screen and (max-width: 425px) {
        display: none;
      }
    }

    .navBar {
      position: relative;
      align-items: center;
      background: white;
      max-width: 260px;
      min-width: 65px;
      padding: 2px 0px 2px 0px;
      overflow-y: scroll;
      overflow-x: hidden;

      .sidebar-down {
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      .links {
        a {
          color: gray;
          width: 100%;
          position: relative;
          padding: 0.5rem 0rem;

          &:hover {
            border-color: $blue;
            color: $blue;
            cursor: pointer;
          }

          &:focus {
            border-color: $blue;
            color: $blue;
            cursor: pointer;
          }
        }

        .appLinkIcon {
          padding: 2px 17px;
          cursor: pointer;
          margin: 0px 0 8px;
          vertical-align: middle;
        }

        .navLink {
          @include transition(ease all 0.4s);
          text-decoration: none;
          display: flex;
          color: gray;
          align-items: center;
    
          .MuiSvgIcon-root {
            margin-right: 7px;
            vertical-align: middle;
            color: gray;
            cursor: pointer;
    
            .MuiStepIcon-root {
              color: blueviolet;
    
              :active {
                color: $blue !important;
              }
            }
          }
    
          &:hover {
            @include transition(ease all 0.4s);
            background: rgba(0, 0, 0, 0.03);
            border-color: $blue;
            color: $blue;
          }
        }

        .is-active {
          position: relative;
          color: #437EF7;
          background-color: #F3F9FE
        }
        .is-active::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: $blue;
          border-radius: 0px 10px 10px 0px;
        }
      }

      .logo-section {
        display: flex;
        align-items: center;

        .menuBox {
          @include transition(ease all 0.4s);
          margin: 14px 14px 14px 14px;
          cursor: pointer;
  
          .menuIcon {
            display: flex;
            width: 30px;
            height: 30px;
            cursor: pointer;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            padding: 0.2rem;
  
            :hover {
              color: $blue;
              border-color: $blue;
            }
  
            &:hover {
              cursor: pointer;
              background: rgba(0, 0, 0, 0.03);
              border-color: $blue;
              color: $blue;
            }
  
            span {
              position: relative;
              display: inline-block;
              width: 100%;
              height: 2px;
              background: gray;
              vertical-align: middle;
  
              &::before {
                @include transition(ease all 0.4s);
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: -9px;
                background: gray;
                color: $blue;
              }
  
              &::after {
                @include transition(ease all 0.4s);
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 9px;
                background: gray;
              }
            }
          }
  
          &:hover {
            @include transition(ease all 0.4s);
  
            .menuIcon {
              span {
                @include transition(ease all 0.4s);
                background: $blue;
  
                &::before {
                  background: $blue;
                  @include transition(ease all 0.4s);
                  left: -1px;
                  width: 70%;
                  transform: rotate(-45deg);
                  top: -6px;
                }
  
                &::after {
                  background: $blue;
                  @include transition(ease all 0.4s);
                  width: 70%;
                  left: -1px;
                  transform: rotate(45deg);
                  top: 6px;
                }
              }
            }
          }
  
          &.collapsed {
            @include transition(ease all 0.4s);
  
            span {
              position: relative;
              display: inline-block;
              width: 100%;
              height: 2px;
              background: gray;
              vertical-align: middle;
  
              &::before {
                @include transition(ease all 0.4s);
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: -9px;
                background: gray;
                color: $blue;
              }
  
              &::after {
                @include transition(ease all 0.4s);
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 9px;
                background: gray;
              }
            }
  
            &:hover {
              cursor: pointer;
              border-color: $blue;
              color: $blue;
  
              .menuIcon {
                span {
                  background: $blue;
                  color: $blue;
  
                  &::before {
                    background: $blue;
                    @include transition(ease all 0.4s);
                    left: 8px;
                    width: 70%;
                    transform: rotate(45deg);
                    top: -6px;
                  }
  
                  &::after {
                    background: $blue;
                    @include transition(ease all 0.4s);
                    width: 70%;
                    left: 8px;
                    transform: rotate(-45deg);
                    top: 6px;
                  }
                }
              }
            }
          }
        }
  
        .logo {
          padding: 0 7px;
          margin-bottom: 14px;
          margin-top: 14px;
          overflow: hidden;
          height: 2.5rem;
        }
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .navBar::-webkit-scrollbar {
      display: none;
    }

    .navBarContent {
      @include transition(ease all 0.4s);
      position: relative;
      padding: 10px;
      opacity: 1;
      max-width: 200px;

      &.collapsed {
        @include transition(ease all 0.4s);
        opacity: 0;
        overflow: hidden;
        white-space: nowrap;
        max-width: 0;
        padding: 10px;
      }

      .contentGroup {
        overflow: hidden;

        .logo {
          padding: 14px;
          max-width: 100px;
          overflow: hidden;
        }

        .activeNavLink {
          color: $blue;
        }
      }
    }
  }

  .expand {
    padding-bottom: 3px !important;
  }

  .content {
    overflow-y: scroll;
    background-size: cover;
    background-color: rgba(250,251,252,255);

    .maincontent {
      max-height: 80px;

      .content2 {
        width: 900px;

        label {
          font-size: 14px;
          line-height: 1.2em;
          padding-left: 8px;
          white-space: pre;

          sub {
            font-size: 12px;
            font-weight: 500;
          }
        }

        h2 {
          font-size: revert;
          margin: 10px 7px 0px 7px;
          white-space: nowrap;

          @media only screen and (max-width: 768px) {
            font-size: 19px !important;
          }
        }
      }

      .content3 {
        padding: 45px !important;
      }

      .profilecontent {
        padding: 10px;
        text-align: right;

        .innercontentDetails {
          padding-top: 3px;
          width: 250px;
          text-align: right;
          margin-top: 3px;
          margin-left: -66px !important;

          label {
            font-size: 13px;
            line-height: 1.2em;

            sub {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }

        .innercontent {
          width: 50px;
          padding-bottom: 2px;

          .profile {
            border: 2px solid $blue;
            border-radius: 8px;
            width: 35px;
            height: 35px;
            padding: 3px;
            vertical-align: middle;
            color: white;
            background-color: $blue;
          }
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  display: inline;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.3);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

html,
body {
  width: 100%;
  height: 100%;
  font-smooth: always;
}

img {
  vertical-align: top;
  max-width: 70%;
}

.layout-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.layout-column {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-wrap {
  flex-wrap: wrap;
}

.layout-row aside {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.layout-row aside.flex-20 {
  max-width: 20%;
  -webkit-box-flex: 1 1 20%;
  -moz-box-flex: 1 1 20%;
  -webkit-flex: 1 1 20%;
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
}

.layout-row aside.flex-25 {
  max-width: 25%;
  -webkit-box-flex: 1 1 25%;
  -moz-box-flex: 1 1 25%;
  -webkit-flex: 1 1 25%;
  -ms-flex: 1 1 25%;
  flex: 1 1 25%;
}

.layout-row aside.flex-30 {
  max-width: 30%;
  -webkit-box-flex: 1 1 30%;
  -moz-box-flex: 1 1 30%;
  -webkit-flex: 1 1 30%;
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
}

.layout-row aside.flex-33 {
  max-width: 33.333%;
  -webkit-box-flex: 1 1 33.333%;
  -moz-box-flex: 1 1 33.333%;
  -webkit-flex: 1 1 33.333%;
  -ms-flex: 1 1 33.333%;
  flex: 1 1 33.333%;
}

.layout-row aside.flex-40 {
  max-width: 40%;
  -webkit-box-flex: 1 1 40%;
  -moz-box-flex: 1 1 40%;
  -webkit-flex: 1 1 40%;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
}

.layout-row aside.flex-50 {
  max-width: 50%;
  -webkit-box-flex: 1 1 50%;
  -moz-box-flex: 1 1 50%;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}

.layout-row aside.flex-60 {
  max-width: 60%;
  -webkit-box-flex: 1 1 60%;
  -moz-box-flex: 1 1 60%;
  -webkit-flex: 1 1 60%;
  -ms-flex: 1 1 60%;
  flex: 1 1 60%;
}

.layout-row aside.flex-66 {
  max-width: 66.666%;
  -webkit-box-flex: 1 1 66.666%;
  -moz-box-flex: 1 1 66.666%;
  -webkit-flex: 1 1 66.666%;
  -ms-flex: 1 1 66.666%;
  flex: 1 1 66.666%;
}

.layout-row aside.flex-75 {
  max-width: 75%;
  -webkit-box-flex: 1 1 75%;
  -moz-box-flex: 1 1 75%;
  -webkit-flex: 1 1 75%;
  -ms-flex: 1 1 75%;
  flex: 1 1 75%;
}

.layout-row aside.flex-80 {
  max-width: 80%;
  -webkit-box-flex: 1 1 80%;
  -moz-box-flex: 1 1 80%;
  -webkit-flex: 1 1 80%;
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
}

.layout-row aside.flex-100 {
  max-width: 100%;
  -webkit-box-flex: 1 1 100%;
  -moz-box-flex: 1 1 100%;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
