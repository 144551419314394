.selected {
    min-width: auto !important;
    border-radius: 50% !important;
    width: 28px;
    height: 28px;
    background-color: #437EF7 !important;
    color: #fefdfd !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #e0e0e0 !important;
    transition: 0ms !important;
}

.selected:hover {
    background-color: #437EF7;
}

.unSelected {
    min-width: auto !important;
    border-radius: 50% !important;
    width: 28px;
    height: 28px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #e0e0e0 !important;
}

.errorQuestion {
    font-weight: 600;
    color: red;
}

.normalQuestion {
    font-weight: 600;
}