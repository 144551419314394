.rootCard {
    height: calc(100vh - 12.5rem);
    scrollbar-width: 1px !important;
    -ms-overflow-style: inherit;
    overflow-y: scroll !important;

    .noDataNotification {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 1.5rem;
        font-weight: 500;
    }
}

.rootCard::-webkit-scrollbar {
    display: inline;
    width: 0.5rem;
}