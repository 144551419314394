#comment_style {
  font-size: 16px;
  font-family: Arial;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#comment_date_style {
  font-size: 12px;
  position: relative;
  bottom: 0;
  right: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: auto; 
  align-self: flex-end; 
}