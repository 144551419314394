.selectedTab {
    background-color: gray;
}

.treeView::-webkit-scrollbar{
    display: block !important;
}

.isLoading {
    padding: 10px 20px;
    background: rgba(0, 110, 255, 0.308);
}
