#cardChipCompleted,#cardChipInProgress,#cardChipNew{
    border-radius: 0.25rem; 
    font-weight: 500;
    margin-top: -10px;
}
#cardChipCompleted{ 
    background-color: #F0FAF0 ;
    color: #2D8A39; 
}
#cardChipInProgress{   
    background-color: #F5FAFF;
    color: #437EF7;
}
#cardChipNew{
    background-color: #F7F7F8 ;
    color: #272D37;
}