
.dataGrid {
    display: flex;
    justify-content: center;

    .mainTitle {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
}
