/* CustomScrollbar.module.scss */

/* For webkit-based browsers (Chrome, Safari, etc.) */
/* Customize the scrollbar */
::-webkit-scrollbar {
   display: none; //it will hide the all other scrollbar
  }
  
  /* Customize the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the thumb */
    border-radius: 6px; /* Set the border radius of the thumb */
  }
  
  /* Hide the default scrollbar provided by Material-UI */
  /* Set your desired height for the scrollbar container */
  .myScrollbarContainer {
    overflow-y: auto;
    scrollbar-width: 1px !important; /* Hide the default scrollbar in Firefox */
   -ms-overflow-style: inherit; /* Hide the default scrollbar in IE 10+ */
  }
  
  /* Hide the default scrollbar in webkit-based browsers (Chrome, Safari, etc.) */
  .myScrollbarContainer::-webkit-scrollbar {
    display: inline;
    width: 0.5rem;
  }
  
  .sessionSection {
    // height: calc(100vh - 28rem);
    max-height: 10rem;
    overflow-y: scroll;
    scrollbar-width: 1px; 
   -ms-overflow-style: inherit; 
  }
  .goalSection {
    height: calc(100vh - 13rem);
    overflow-y: scroll;
    scrollbar-width: 1px; 
   -ms-overflow-style: inherit; 
  }