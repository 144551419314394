#cardChip{
    border-radius: 0.5rem;
}

#cardChipHigh,#cardChipMedium,#cardChipLow{
    border-radius: 0.5rem; 
    font-weight: 500;
}
#cardChipHigh{ 
    background-color: #FFF2F0 ;
    color: #E2341D; 
}
#cardChipMedium{   
    background-color: #FFF8EB ;
    color: #EEA23E;
}
#cardChipLow{
    background-color: #F0FAF0 ;
    color: #2D8A39;
}

#DateRange,#Status{
    color: #5F6D7E;
    font-weight: 500;
}

#expandMoreIcon:hover{  
       background-color: transparent;
}
#expandLessIcon:hover{  
    background-color: transparent;    
}

#expandMoreIcon:active{  
    background-color: transparent; 
    box-shadow: 'none',
}
#expandLessIcon:active{  
 background-color: transparent;
 box-shadow: 'none',   
}
#expandMoreIcon,#expandLessIcon{  
    color: #000; 
}

#expandMenu{  
    background-color: transparent;
    box-shadow: 'none';
    color:'#5F6D7E';
}

#editIcon{
    color: #5F6D7E;  
}

#goalCardEmailSlogan{
    background-color: #F0FAF0 ;
    color: #2D8A39;
}
